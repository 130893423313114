import * as consts from "../consts";
import axios from "axios";

export function setBusy(busy, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_BUSY,
      payload: busy,
    });
    resolve();
  });
}

export function setAlert(alert, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_ALERT,
      payload: alert,
    });
    resolve();
  });
}

export function getResource(path, dispatch) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${path}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-WWW:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve resource ${path}`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getPublicGames(dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.SERVICE_URL}/public/games`, options)
      .then((response) => {
        let publicGames = response.data;
        resolve(publicGames);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-WWW:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve public games`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getPublicGame(gameID, dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.SERVICE_URL}/public/games/${gameID}`, options)
      .then((response) => {
        let publicGame = response.data;
        resolve(publicGame);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-WWW:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve public game`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getPublicClues(gameID, dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.SERVICE_URL}/public/games/${gameID}/clues`, options)
      .then((response) => {
        let publicClues = response.data;
        resolve(publicClues);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-WWW:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve public clues`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getPublicLeaderboard(gameID, dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.SERVICE_URL}/public/games/${gameID}/leaderboard`, options)
      .then((response) => {
        let publicLeaderboard = response.data;
        resolve(publicLeaderboard);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-WWW:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve public leaderboard`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getPublicCandidates(gameID, dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.SERVICE_URL}/public/games/${gameID}/candidates`, options)
      .then((response) => {
        let publicCandidates = response.data;
        resolve(publicCandidates);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-WWW:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve public candidates`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function canUseWebP() {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = function () {
      let result = image.width > 0 && image.height > 0;
      resolve(result);
    };
    image.onerror = function () {
      resolve(false);
    };
    image.src =
      "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
  });
}
