export const SERVICE_URL = process.env.SERVICE_URL;
export const SITE_URL = process.env.SITE_URL;
export const BLOB_URL = process.env.BLOB_URL;
export const SHARED_URL = process.env.SHARED_URL;
export const VAPID_PUBLIC_KEY = process.env.VAPID_PUBLIC_KEY;
export const RESOURCE_URL_PRIVACY = process.env.RESOURCE_URL_PRIVACY;
export const RESOURCE_URL_TERMS = process.env.RESOURCE_URL_TERMS;
export const RESOURCE_URL_COOKIES = process.env.RESOURCE_URL_COOKIES;
export const RESOURCE_URL_ABOUT = process.env.RESOURCE_URL_ABOUT;

export const PANEL_EMPTY = "/";
export const PANEL_MAIN = "/";
export const PANEL_ABOUT = "/About";
export const PANEL_PRIVACY = "/Privacy";
export const PANEL_COOKIES = "/Cookies";
export const PANEL_TERMS = "/Terms";
export const PANEL_FAQ = "/FAQ";
export const PANEL_PRODUCTS = "/Products";
export const PANEL_LEADERBOARDS = "/Leaderboards";

export const ACTION_SET_BUSY = "ACTION_SET_BUSY";
export const ACTION_SET_ALERT = "ACTION_SET_ALERT";

export const ALERT_TYPE_ERROR = "Error";
export const ALERT_TYPE_SUCCESS = "Success";

export const THEME_LIGHT = {
  palette: {
    type: "light",
    primary: {
      main: "#03a9f4",
    },
    secondary: {
      main: "#ff9100",
    },
    alert: {
      error: "#b2102f",
      success: "#52b202",
    },
    icons: {
      default: "#03a9f4",
      home: "#03a9f4",
      join: "#03a9f4",
      about: "#03a9f4",
      faq: "#03a9f4",
      privacy: "#03a9f4",
      cookies: "#03a9f4",
      terms: "#03a9f4",
      settings: "#03a9f4",
      game: "#ff9100",
      welcome: "#ff9100",
      clues: "#ef6694",
      team: "#ab47bc",
      feed: "#008fcc",
      votes: "#d32f2f",
      rules: "#00a152",
      score: "#283593",
      sponsors: "#26a69a",
      prizes: "#ef6c00",
      heart: "#f73378",
      badge: "#a6d4fa",
      teamHeart: "#bdbdbd",
      disabled: "#bdbdbd",
      required: "#03a9f4",
      rejected: "#b2102f",
    },
  },
};
