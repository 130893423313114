import React, { useEffect, useContext, useState, useRef } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  GridList,
  GridListTile,
  GridListTileBar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import {
  getPublicGame,
  getPublicCandidates,
  getPublicLeaderboard,
  getPublicClues,
} from "../../store/actions";
import * as consts from "../../consts";
import { State } from "../../store/state";
import {
  useParams,
  useLocation,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import LazyLoad from "react-lazyload";
import ReactCardFlip from "react-card-flip";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Leaderboard(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    hearts: {
      textAlign: "right",
      marginBottom: theme.spacing(1),
    },
    teamHeart: {
      color: theme.palette.icons.teamHeart,
    },
    clueTitle: {
      fontSize: "2rem",
      marginBottom: theme.spacing(2),
    },
    categoryWrapper: {
      margin: "auto",
    },
    image: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    cardActions: {
      flexDirection: "row-reverse",
    },
    candidates: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {},
    gridTile: {
      height: "auto !important",
    },
    listItem: {
      height: "auto !important",
    },
    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { gameID } = useParams();
  const [game, setGame] = useState(null);
  const [clues, setClues] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [leaderboard, setLeaderboard] = useState({});
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);

  useInterval(() => {
    if (photos.length > 4) {
      let swapIndex = Math.floor(Math.random() * 5);
      let alreadyDisplayed = null;
      let item = null;
      do {
        item = candidates[Math.floor(Math.random() * candidates.length)];
        alreadyDisplayed = photos.find((photo) => {
          return photo.teamID == item.teamID && photo.clueID == item.clueID;
        });
      } while (alreadyDisplayed);
      let newPhotos = photos.slice();
      newPhotos.map((newPhoto) => {
        newPhoto.isFlipped = false;
      });
      newPhotos[swapIndex] = { ...item, isFlipped: true };
      setPhotos(newPhotos);
    }
  }, 5000);

  useInterval(() => {
    getPublicLeaderboard(gameID, dispatch).then(
      (getPublicLeaderboardResult) => {
        getPublicLeaderboardResult.scores.sort((a, b) =>
          a.totalScore > b.totalScore ? -1 : b.totalScore > a.totalScore ? 1 : 0
        );
        setLeaderboard(getPublicLeaderboardResult);
        getPublicCandidates(gameID, dispatch).then(
          (getPublicCandidatesResult) => {
            let sortedCandidates = getPublicCandidatesResult.sort(
              () => Math.random() - 0.5
            );
            setCandidates(sortedCandidates);
          }
        );
      }
    );
  }, 300000);

  useEffect(() => {
    if (gameID) {
      setBusy(true);
      getPublicGame(gameID, dispatch)
        .then((getPublicGameResult) => {
          setGame(getPublicGameResult);
          getPublicClues(gameID, dispatch)
            .then((getPublicCluesResult) => {
              setClues(getPublicCluesResult);
              getPublicLeaderboard(gameID, dispatch)
                .then((getPublicLeaderboardResult) => {
                  getPublicLeaderboardResult.scores.sort((a, b) =>
                    a.totalScore > b.totalScore
                      ? -1
                      : b.totalScore > a.totalScore
                      ? 1
                      : 0
                  );
                  setLeaderboard(getPublicLeaderboardResult);
                  getPublicCandidates(gameID, dispatch)
                    .then((getPublicCandidatesResult) => {
                      let sortedCandidates = getPublicCandidatesResult.sort(
                        () => Math.random() - 0.5
                      );
                      setCandidates(sortedCandidates);
                      setPhotos(
                        sortedCandidates.slice(
                          0,
                          sortedCandidates.length > 4
                            ? 5
                            : sortedCandidates.length - 1
                        )
                      );
                      setBusy(false);
                    })
                    .catch(() => {
                      setBusy(false);
                    });
                })
                .catch(() => {
                  setBusy(false);
                });
            })
            .catch(() => {
              setBusy(false);
            });
        })
        .catch(() => {
          setBusy(false);
        });
    } else {
      setBusy(false);
    }
  }, [gameID]);

  const renderLeaderboard = () => {
    return (
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Rankings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Team</TableCell>
                    <TableCell component="th">Votes</TableCell>
                    <TableCell component="th">Clues</TableCell>
                    <TableCell component="th">Penalty</TableCell>
                    <TableCell component="th">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaderboard.scores &&
                    leaderboard.scores.map((score, index) => {
                      if (index < 10 || score.teamID == state.game.team.id) {
                        return (
                          <TableRow key={score.teamID}>
                            <TableCell component="th" scope="row">
                              {score.title}
                            </TableCell>
                            <TableCell>{score.voteScore}</TableCell>
                            <TableCell>{score.clueScore}</TableCell>
                            <TableCell>
                              {score.cluePenalty + score.votePenalty}
                            </TableCell>
                            <TableCell>{score.totalScore}</TableCell>
                          </TableRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  const renderCandidates = () => {
    return (
      <div className={classes.candidates}>
        <GridList className={classes.gridList} cols={5}>
          {photos.map((photo, index) => {
            let currentClue = clues.find((clue) => {
              return clue.id == photo.clueID;
            });
            return (
              <GridListTile
                key={index}
                classes={{ root: classes.gridTile, tile: classes.listItem }}
              >
                <ReactCardFlip
                  isFlipped={photo.isFlipped}
                  flipDirection="horizontal"
                >
                  <picture>
                    <source
                      srcSet={`${consts.BLOB_URL}${game.id}/clues/${game.id}-${photo.clueID}-${photo.teamID}.webp`.toLowerCase()}
                      type="image/webp"
                    />
                    <img
                      src={`${consts.BLOB_URL}${game.id}/clues/${game.id}-${photo.clueID}-${photo.teamID}.jpg`.toLowerCase()}
                      alt={photo.title}
                      className={classes.image}
                    />
                  </picture>

                  <img
                    src={`https://www.cluesnaps.com/images/icon-1024x1024_maskable.png`.toLowerCase()}
                    alt="Clue Snaps"
                    className={classes.image}
                  />
                </ReactCardFlip>
                {!photo.isFlipped && (
                  <GridListTileBar
                    titlePosition="bottom"
                    title={currentClue.title}
                    actionPosition="right"
                    className={classes.titleBar}
                  />
                )}
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        <Grid item container xs={12} className={classes.section}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              {game.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderCandidates()}
          </Grid>
          <Grid item xs={12}>
            {renderLeaderboard()}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
