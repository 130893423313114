import React, { useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  Typography,
  Link,
  Drawer,
  List,
  ListItem,
  Divider,
  Toolbar,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  Info as AboutIcon,
  LiveHelp as FAQIcon,
  Lock as PrivacyIcon,
  Description as TermsIcon,
  Description as CookiesIcon,
  Storefront as ProductsIcon,
  Poll as LeaderboardsIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";

import * as consts from "../../consts";

export default function LeftNavigation(props) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: "70vw",
    },
    title: {
      fontSize: "2rem",
    },
    link: {
      margin: "0px",
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      fontSize: "1rem",
      color: theme.palette.icons.default,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <Toolbar>
        <IconButton
          aria-label="close menu"
          onClick={() => {
            props.onSetOpenDrawer(false);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" className={classes.title}>
          Menu
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_MAIN}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <HomeIcon fontSize="small" className={classes.icon} />
            <span>Home</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_FAQ}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <FAQIcon fontSize="small" className={classes.icon} />
            <span>FAQ</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_ABOUT}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <AboutIcon fontSize="small" className={classes.icon} />
            <span>About</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_PRIVACY}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <PrivacyIcon fontSize="small" className={classes.icon} />
            <span>Privacy</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_TERMS}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <TermsIcon fontSize="small" className={classes.icon} />
            <span>Terms</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_COOKIES}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <CookiesIcon fontSize="small" className={classes.icon} />
            <span>Cookies</span>
          </Link>
        </ListItem>
        {/* <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_PRODUCTS}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <ProductsIcon fontSize="small" className={classes.icon} />
            <span>Products</span>
          </Link>
        </ListItem> */}
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_LEADERBOARDS}
            className={classes.link}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <LeaderboardsIcon fontSize="small" className={classes.icon} />
            <span>Leaderboards</span>
          </Link>
        </ListItem>
      </List>
    </Drawer>
  );
}
