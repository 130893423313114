import React, { useReducer } from "react"; // eslint-disable-line no-unused-vars
import * as consts from "../consts";
import { State } from "./state";

const initialState = {
  busy: true,
  alert: null,
};

function reducer(state, action) {
  switch (action.type) {
    case consts.ACTION_SET_BUSY:
      return { ...state, busy: action.payload };
    case consts.ACTION_SET_ALERT:
      return { ...state, alert: action.payload };
    case consts.ACTION_SET_DARK_MODE:
      if (action.payload) {
        localStorage.setItem(consts.STORAGE_DARK_MODE, action.payload);
      } else {
        localStorage.removeItem(consts.STORAGE_DARK_MODE);
      }
      return { ...state, darkMode: action.payload };
  }
}

export function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <State.Provider value={value}>{props.children}</State.Provider>;
}
