import React, { useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  Grid,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  Info as AboutIcon,
  LiveHelp as FAQIcon,
  Lock as PrivacyIcon,
  Description as TermsIcon,
  Description as CookiesIcon,
  Storefront as ProductsIcon,
  Poll as LeaderboardsIcon,
  NavigateNext as BreadcrumbsIcon,
} from "@material-ui/icons";
import { NavLink, useRouteMatch } from "react-router-dom";
import * as consts from "../../consts";
import { State } from "../../store/state";

export default function Breadcrumb(props) {
  const useStyles = makeStyles((theme) => ({
    breadcrumb: { marginBottom: theme.spacing(1) },
    section: { marginBottom: theme.spacing(1) },
    link: {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    label: {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    pageTitle: {
      display: "inline",
      fontSize: "1rem",
      fontWeight: "normal",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  return (
    <Grid item container xs={12} className={classes.section}>
      <Grid item xs={12}>
        <Breadcrumbs
          maxItems={2}
          separator={<BreadcrumbsIcon fontSize="small" />}
          className={classes.breadcrumb}
        >
          <Link
            component={NavLink}
            to={`${consts.PANEL_MAIN}`}
            className={classes.link}
          >
            <HomeIcon className={classes.icon} />
            <span>Home</span>
          </Link>
          {useRouteMatch({ path: `${consts.PANEL_ABOUT}` }) && (
            <Typography component="div" className={classes.label}>
              <AboutIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>About</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_PRIVACY}` }) && (
            <Typography component="div" className={classes.label}>
              <PrivacyIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Privacy</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_TERMS}` }) && (
            <Typography component="div" className={classes.label}>
              <TermsIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Terms</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_COOKIES}` }) && (
            <Typography component="div" className={classes.label}>
              <CookiesIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Cookies</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_FAQ}` }) && (
            <Typography component="div" className={classes.label}>
              <FAQIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>FAQ</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_PRODUCTS}` }) && (
            <Typography component="div" className={classes.label}>
              <ProductsIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Products</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_LEADERBOARDS}` }) && (
            <Typography component="div" className={classes.label}>
              <LeaderboardsIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Leaderboards</h1>
            </Typography>
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}
