import React, { useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Badge,
  Typography,
  Link,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { State } from "../../store/state";
import LeftNavigation from "./leftNavigation";

import * as consts from "../../consts";

export default function Header(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
      fontSize: "2rem",
    },
    image: {
      width: "100px",
    },
    link: {
      margin: "0px",
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    selected: {},
    drawer: {
      "& .MuiDrawer-paper": {
        width: "50vw",
      },
    },
    drawerTitle: {
      fontSize: "1.5rem",
      margin: "0px",
      padding: "12px",
    },
    badge: {
      color: "#ffffff",
    },
  }));
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { state, dispatch } = useContext(State);

  return (
    <AppBar position="fixed" color="inherit" className={classes.root}>
      {openDrawer && (
        <LeftNavigation
          open={openDrawer}
          onSetOpenDrawer={(open) => {
            setOpenDrawer(open);
          }}
        />
      )}
      <Toolbar>
        <Hidden mdUp={true}>
          <IconButton
            edge="start"
            aria-label="open left menu"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant="h1" color="primary" className={classes.title}>
          <Link
            href={consts.SITE_URL + consts.PANEL_EMPTY}
            className={classes.link}
            color="primary"
          >
            Clue Snaps
          </Link>
        </Typography>
        <Hidden smDown={true}>
          <Link
            component={NavLink}
            to={consts.PANEL_MAIN}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Home
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_FAQ}
            className={classes.link}
            activeClassName={classes.selected}
          >
            FAQ
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_ABOUT}
            className={classes.link}
            activeClassName={classes.selected}
          >
            About
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_PRIVACY}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Privacy
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_TERMS}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Terms
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_COOKIES}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Cookies
          </Link>
          {/* <Link
            component={NavLink}
            to={consts.PANEL_PRODUCTS}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Products
          </Link> */}
          <Link
            component={NavLink}
            to={consts.PANEL_LEADERBOARDS}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Leaderboards
          </Link>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
