import React, { useEffect, useContext, useState } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { getResource } from "../../store/actions";
import * as consts from "../../consts";
import { State } from "../../store/state";

export default function Products(props) {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);

  const renderProducts = () => {
    return (
      <Grid item xs={12}>
        PRODUCTS
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        renderProducts()
      )}
    </Grid>
  );
}
