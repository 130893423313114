import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "./store/provider";
import App from "./components/app";

if (process.env.NODE_ENV === "development") {
  console.log("CSV2-WWW: Loading");
}

let loadApp = function () {
  if (process.env.NODE_ENV === "development") {
    console.log("CSV2-WWW: Initializing React component");
  }
  let app = document.getElementById("app");
  if (app) {
    ReactDOM.render(
      <React.Fragment>
        <ScopedCssBaseline>
          <Router>
            <Provider>
              <App />
            </Provider>
          </Router>
        </ScopedCssBaseline>
      </React.Fragment>,
      app
    );
  } else {
    if (process.env.NODE_ENV === "development") {
      console.log("CSV2-WWW: No element to insert component into");
    }
  }
};
if (document.readyState === "loading") {
  if (process.env.NODE_ENV === "development") {
    console.log("CSV2-WWW: Waiting for document ready state");
  }
  document.addEventListener("DOMContentLoaded", loadApp);
} else {
  loadApp();
}
