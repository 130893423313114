import React, { useEffect, useContext, useState } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
  Avatar,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import { getPublicGames } from "../../store/actions";
import * as consts from "../../consts";
import { State } from "../../store/state";
import { useParams, useHistory, NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

export default function Leaderboards(props) {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "2rem",
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [publicGames, setPublicGames] = useState([]);
  const [gameID, setGameID] = useState("");
  const history = useHistory();

  useEffect(() => {
    setBusy(true);
    getPublicGames(dispatch)
      .then((getPublicGamesResult) => {
        setPublicGames(getPublicGamesResult);
        setBusy(false);
      })
      .catch((exception) => {
        setError(
          exception.response && exception.response.data
            ? exception.response.data
            : "Error: There was an issue getting public games."
        );
        setBusy(false);
      });
  }, []);

  const renderPublicGames = () => {
    return (
      <List className={classes.list}>
        {publicGames.map((game) => {
          return (
            <LazyLoad once key={game.id}>
              <Link
                component={NavLink}
                to={`${consts.PANEL_LEADERBOARDS}/${game.id}`}
                activeClassName={classes.selected}
                color="inherit"
              >
                <ListItem
                  disableGutters={true}
                  classes={{ container: classes.listItem }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt=""
                      src={`${consts.BLOB_URL}${game.id}/banner_thumbnail.jpg`.toLowerCase()}
                      imgProps={{ width: "40", height: "40" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1">{game.title}</Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Divider variant="middle" component="li" />
            </LazyLoad>
          );
        })}
      </List>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        <Grid item container xs={12} className={classes.section}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.header}>
              Public Games
            </Typography>
            {renderPublicGames()}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
