import React, { useContext, useEffect, useState } from "react"; // eslint-disable-line no-unused-vars
import { State } from "../../store/state";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Link,
  Badge,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import {
  Favorite as HeartIcon,
  AllInclusive as InfinityIcon,
  Public as PublicIcon,
  ThumbsUpDown as ThumbsUpDownIcon,
  Group as GroupIcon,
  Loyalty as LoyaltyIcon,
  Gavel as GavelIcon,
  ImageSearch as ImageSearchIcon,
  FindReplace as FindReplaceIcon,
  AccessAlarm as AccessAlarmIcon,
  Poll as PollIcon,
} from "@material-ui/icons";
import * as consts from "../../consts";

export default function Main(props) {
  const { state, dispatch } = useContext(State);
  const useStyles = makeStyles((theme) => ({
    banner: {
      maxHeight: "300px",
      overflow: "hidden",
      marginBottom: theme.spacing(2),
      "& img": { width: "100%" },
    },
    logo: {
      width: "40px",
      height: "40px",
    },
    welcome: {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(4),
    },
    header: {
      fontSize: "2rem",
      textDecoration: "underline",
      marginBottom: theme.spacing(2),
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    heartBadge: {
      color: "#ffffff",
      right: -10,
    },
    approvedBadge: {
      backgroundColor: "#52b202",
      color: "#52b202",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12} className={classes.banner}>
        <img
          src="https://shared.cluesnaps.com/preview.jpg"
          alt="Clue Snaps Banner"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" className={classes.welcome} align="center">
          Welcome to Clue Snaps an online scavenger hunt designed to keep people
          connected regardless of how far away from each other they might be.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h2" className={classes.header} align="center">
          How to play
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <Badge>
                <Link href="https://play.cluesnaps.com">
                  <img
                    src="https://www.cluesnaps.com/images/icon-40x40.png"
                    alt="Play Clue Snaps"
                    className={classes.logo}
                    width="40"
                    height="40"
                  />
                </Link>
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary="Join a game"
              secondary="If you have received an invitation to a game or a team you can use the link included in that email to join a game or if you have a game id you can join a game by going to play.cluesnaps.com"
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
                classes={{
                  badge: classes.approvedBadge,
                }}
              >
                <Avatar
                  alt="Clue Required Sample"
                  src={`${consts.SHARED_URL}required_thumbnail.jpg`.toLowerCase()}
                  imgProps={{ width: "40", height: "40" }}
                />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary="Collect photos to complete clues"
              secondary="Each game will have its own list of clues. To complete a clue you upload an image that you think best represents the clue."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Badge
                badgeContent={<InfinityIcon />}
                color="primary"
                classes={{ badge: classes.heartBadge }}
              >
                <HeartIcon className={classes.heart} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary="Score points and give kudos at the same time"
              secondary="There are two ways to score points. You score points by uploading photos and you score points by receiving hearts. Every player is issued a limited number of hearts that they must give away before the end of the game."
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h2" className={classes.header} align="center">
          Run an Event
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <PublicIcon />
            </ListItemIcon>
            <ListItemText
              primary="Make the game private or public"
              secondary="Run a game that lets the whole world join or limit your audience to a select few."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ThumbsUpDownIcon />
            </ListItemIcon>
            <ListItemText
              primary="Require photo approval or make it automatic"
              secondary="Need to control which photos are acceptable? Use our management interface to approve or reject user submitted content."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              primary="Adjust the team size limits"
              secondary="Control the number of members that can join a single team."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText
              primary="Change how points work"
              secondary="Adjust how many points teams receive for photos and hearts as well as the penalties they receive for not uploading or voting."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GavelIcon />
            </ListItemIcon>
            <ListItemText
              primary="Add your own custom rules to fit your needs"
              secondary="Modify the rules section to support any game mechanisms you can think of."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ImageSearchIcon />
            </ListItemIcon>
            <ListItemText
              primary="Fully customize the clue list"
              secondary="Create your own clue list with as many clues as you think your players can handle."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FindReplaceIcon />
            </ListItemIcon>
            <ListItemText
              primary="Add more clues as the game progresses"
              secondary="Make your game more dynamic by adding more clues as the players hit milestones."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccessAlarmIcon />
            </ListItemIcon>
            <ListItemText
              primary="Adjust how long the game will run"
              secondary="A game can be run in as little as a day or over an entire year and anything inbetween. You can let players into the game early to setup their teams without exposing the clues to them."
            />
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PollIcon />
            </ListItemIcon>
            <ListItemText
              primary="Get a custom leaderboard"
              secondary="A page designed specifically for large displays to show the progress the teams are making can be customized to meet your needs and can even be hosted on your own site."
            />
            <Divider />
          </ListItem>
        </List>
        <Typography variant="body1">
          These are just some of the customizations you can make. Contact our{" "}
          <Link href="mailto:sales@cluesnaps.com">event team</Link> to setup a
          call so we can help you design a game that fits your needs.
        </Typography>
      </Grid>
    </Grid>
  );
}
