import React, { useEffect, useContext, useState } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { getResource } from "../../store/actions";
import * as consts from "../../consts";
import { State } from "../../store/state";
import LegalLinks from "../presentational/legalLinks";

export default function Cookies(props) {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [cookiesMarkup, setCookiesMarkup] = useState("");

  useEffect(() => {
    setBusy(true);
    getResource(consts.RESOURCE_URL_COOKIES, dispatch)
      .then((getResourceResult) => {
        setCookiesMarkup(getResourceResult);
        setBusy(false);
      })
      .catch((exception) => {
        setError(
          exception.response && exception.response.data
            ? exception.response.data
            : "Error: There was an issue getting the cookies page."
        );
        setBusy(false);
      });
  }, []);

  const renderCookies = () => {
    return (
      <Grid item container xs={12}>
        <div dangerouslySetInnerHTML={{ __html: cookiesMarkup }}></div>
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        renderCookies()
      )}
      <LegalLinks />
    </Grid>
  );
}
