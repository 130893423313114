import React, { useEffect, useContext } from "react"; // eslint-disable-line no-unused-vars
import { Route, Switch, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../store/state";
import { setAlert } from "../store/actions";
import Header from "./presentational/header";
import Alert from "./presentational/alert";
import Breadcrumb from "./presentational/breadcrumb";
import Main from "./panels/main";
import About from "./panels/about";
import Privacy from "./panels/privacy";
import Terms from "./panels/terms";
import Cookies from "./panels/cookies";
import FAQ from "./panels/faq";
import Products from "./panels/products";
import Leaderboards from "./panels/leaderboards";
import Leaderboard from "./panels/leaderboard";
import * as consts from "../consts";
import { Paper } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const App = () => {
  const { state, dispatch } = useContext(State);
  const theme = createMuiTheme(consts.THEME_LIGHT);
  const useStyles = makeStyles((theme) => ({
    main: {
      backgroundColor: "#ffffff",
      minHeight: "100vh",
    },
    paper: {
      marginTop: "80px",
      padding: theme.spacing(2),
      minHeight: "100vh",
    },
  }));
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Alert
          alert={state.alert}
          onDismiss={() => {
            setAlert(null, dispatch);
          }}
        />
        <Header />
        <Paper className={classes.paper}>
          <Breadcrumb />
          <Switch>
            <Route path={`${consts.PANEL_ABOUT}`} render={() => <About />} />
            <Route
              path={`${consts.PANEL_PRIVACY}`}
              render={() => <Privacy />}
            />
            <Route
              path={`${consts.PANEL_COOKIES}`}
              render={() => <Cookies />}
            />
            <Route path={`${consts.PANEL_TERMS}`} render={() => <Terms />} />
            <Route path={`${consts.PANEL_FAQ}`} render={() => <FAQ />} />
            <Route
              path={`${consts.PANEL_PRODUCTS}`}
              render={() => <Products />}
            />
            <Route
              path={`${consts.PANEL_LEADERBOARDS}/:gameID`}
              render={() => <Leaderboard />}
            />
            <Route
              path={`${consts.PANEL_LEADERBOARDS}`}
              render={() => <Leaderboards />}
            />
            <Route render={() => <Main />} />
          </Switch>
        </Paper>
      </div>
    </ThemeProvider>
  );
};
export default App;
